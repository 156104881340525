(function ($, app) {
    'use strict';

    app.updateCongressSubscribe = function(step) {
        setStep(step);
    }

    function setStep(step) {
        var steps = $(".steps").children();

        for (var i = 0; i < steps.length; i++) {
            $(steps[i]).removeClass("done");
            $(steps[i]).removeClass("active");

            if (i < step)
            {
                $(steps[i]).addClass("done");
            }
            else if (i == step)
            {
                $(steps[i]).addClass("active");
            }
        }

        var label = $(".steplabels").children().eq(step);
        $(".label-mobile")[0].innerText = label[0].innerText;
    }

})(jQuery, window.app);