(function ($, app) {
	"use strict";

	$(window).on("pageNavigation", init);
	var run = false;
	function init() {

		if (!run) {
			$('body').on('submit',
				'.periodictrainingfilter',
				function(ev) {
					ev.preventDefault();
					var self = $(this);

					var selected = $('option:selected', self);

					if (selected.is(":disabled")) {
						self.blur();
						selected.parent().focus();
						
						return;
					}


					if (typeof (window.ga) === 'function') {
						window.ga('send', 'event', 'targetmarket', 'click', selected.val());
					}
					window.location = selected.val();
				});
		}
	}

})(jQuery, window.app);