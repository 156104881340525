

(function ($, app) {
	'use strict';
	$(window).on('pageNavigation', init);

	var debouncedRemoteValidator = app.debounce(validateRemoteEmail, 250);

	var defaultErrorMessage = 'Vul een uniek e-mail adres in. Je kunt zich slechts een keer per congres inschrijven. In de rechterbalk zie je een link naar je winkelwagen.';

	var elements = {};

	function init() {
		if (!app.subscribe() || !$('#Email').length) {
			return;
		}

		elements.emailInput = $('#Email').addClass('uniqueEmail');

		elements.noEmailInput = $('#NoEmail');
		
		elements.noEmailInput.parent().parent().hide();

		

		var congressId = $('#CongressId').val();
        var subscriptionId = $('#SubscriptionId').val();

		elements.validator = elements.emailInput.parents('form').validate();

		$.validator.addMethod('uniqueRemoteEmail',
			function (value, element, param) {
				if (this.optional(element)) {
					return 'dependency-mismatch';
				}

				debouncedRemoteValidator.call(this, value, element, param);

				return 'pending';
			});

		elements.emailInput.rules('add', {
			uniqueRemoteEmail: {
				url: '/api/hasemailsubscribed',
				data: {
					Email: function () {
						return elements.emailInput.val();
					},
                    CongressId: congressId,
                    SubscriptionId: subscriptionId
				},
				datatype: 'json'
			},
			messages: {
				uniqueRemoteEmail: defaultErrorMessage
			}
		});
	}


	function validateRemoteEmail(value, element, param) {

		var previous = this.previousValue(element, 'uniqueRemoteEmail');
		if (!this.settings.messages[element.name]) {
			this.settings.messages[element.name] = {};
		}
		previous.originalMessage = this.settings.messages[element.name].remote;
		this.settings.messages[element.name].remote = previous.message;

		param = typeof param === 'string' ? { url: param } : param;

		if (previous.old === value) {
			return previous.valid;
		}

		previous.old = value;
		var validator = this;
		this.startRequest(element);
		var data = {};

		data[element.name] = value;
		$.ajax($.extend(true, {
			url: param,
			mode: 'abort',
			method:'post',
			port: 'validate' + element.name,
			dataType: 'json',
			data: data,
			success: function (response) {
				validator.settings.messages[element.name].remote = previous.originalMessage;

				var hasSubscribed = typeof response !== 'undefined' && response !== null && response.hasEmailSubscribed === true;
				
				if (!hasSubscribed) {
					var submitted = validator.formSubmitted;
					validator.prepareElement(element);
					validator.formSubmitted = submitted;
					validator.successList.push(element);
					delete validator.invalid[element.name];
					validator.showErrors();

				//	elements.noEmailInput.parent().parent().hide();
				} else {
					
				//	elements.noEmailInput.parent().parent().show();
					var errors = {};
					var message = response.message || defaultErrorMessage;
					errors[element.name] = previous.message = $.isFunction(message) ? message(value) : message;
					validator.invalid[element.name] = true;
					validator.showErrors(errors);
				}
				previous.valid = !hasSubscribed;
				validator.stopRequest(element, !hasSubscribed);
			}
		}, param));
	}

})(jQuery, window.app);