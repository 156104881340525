(function ($, app) {
	'use strict';

	$(window).on('pageNavigation', init);

	function init() {

		var url = window.location.pathname;

		if (!(url.match(/\/persoonsgegevens/) !== null)) {
			return;
		}


		$('#EqualInvoiceAddress').on('change', updateInvoiceAddressState).trigger('change');

		if ($('.accreditaties :checkbox').length) {
			$('.accreditaties :checkbox').button({
				icons: {
					primary: 'ui-icon-check'
				}
			});
			// remove old asp.net mvc checkbox infrastructure
			$('.accreditaties input:hidden').filter('[value=false]').remove();

			$('.accreditaties').Accreditations({ input: '#BIGNumber, #BIGAtCongress' });
		} else {
			$('#BIGNumber, #BIGAtCongress').parent().parent().hide();
		}
        
		app.equalWidths();

		function updateInvoiceAddressState() {

			var p = $('.invoiceDetails');

			if ($(this).is(':checked')) {
				p.hide();
			} else {
				p.show();
			}

		}
		accredationFieldsArrayValidator();

		function accredationFieldsArrayValidator() {
			$('input[id^="LatestAccreditationCodes_"]').each(function () {
				var codeInput = $(this);
				codeInput.rules('add', {
					required: true,
					messages: {
						required: "Verplicht."
					}
				});
			});
		}
	}
})(jQuery, window.app);