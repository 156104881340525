(function ($, app) {
    'use strict';

    $(window).on('pageNavigation', init);

    var queueUpdateFast = app.debounce(update, 10);

    var queueUpdate = app.debounce(update, 1000);

    var inited = false;

    function init() {
        if (inited) {
            return;
        }

        inited = true;

        if (!app.subscribe()) {
            return;
        }

        $('body').on('change', '.subscribeFormElement input', function () {
            queueUpdate();
        });

        $(window).on('ns:forcedFormStorage',
                function () {
                    queueUpdateFast();
                });
    }

    function update() {
        //   console.log('Logging update', new Date());

        var rawData = $.grep($('.subscribeFormElement').serializeArray(),
            function (n) {
                return n.name !== '__RequestVerificationToken';
            });

        var data = {
            logData: JSON.stringify(rawData),
            email: $('#Email').val(),
            congressId: $('#CongressId').val(),
            key: $('#SubscribeKey').val(),
            firstDisplay: $('#FirstDisplay').val()
        };

        //    console.log('Logging update:', data);

        $.ajax({ url: '/api/subscribeFormLog', data: JSON.stringify(data), method: 'POST', dataType: 'json', contentType: "application/json; charset=utf-8" }).then(function () {
            //   console.log(arguments);
        }, function () {
            console.log(arguments);
        });
    }
})(jQuery, window.app);