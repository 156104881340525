(function ($, app) {
    return;

    function setFields(institution) {
        $('#Organisation').val(institution.name);
        $('#Address_Name').val(institution.location);
        $('#Address_Zipcode').val(institution.zipCode);

        if (typeof institution.address != 'undefined') {
            $('#Address_AddressLine').val(institution.address + ' ' + institution.housenumber);
        } else {
            $('#Address_AddressLine').val('');
        }

        $('#Address_City').val(institution.city);
        $('#Brin').val(institution.brin);
        $('#Phone').val(institution.phone);

    }

    $.widget('custom.institutionselectmenu', $.ui.selectmenu, {
        _renderItem: _renderItem
    });

    $(window).on('pageNavigation', initSearchForInstitution);

    var searchForInstitutionClass = 'searchForInstitution';

    function initSearchForInstitution() {
        var emailValidatedField = $('#EmailValidated');
        if (emailValidatedField.length && !emailValidatedField.hasClass(searchForInstitutionClass)) {
            emailValidatedField.change(app.debounce(searchForInstitution, 100));
            emailValidatedField.addClass('searchForInstitutionClass');

            setAutocompleteInstitution();
        }
    }

    var institutions = [];

    function setAutocompleteInstitution() {
        var instance = $('#Organisation')
			.autocomplete({
			    source: function (request, response) {
			        var myArray = [];

			        var clientSubscribeModel = {
			            Type: 'name',
			            Data: request.term
			        }

			        $.ajax({ url: '/api/institutions/search/name?data=' + request.term, data: clientSubscribeModel, method: 'POST', dataType: 'json' }).then(function (returnData) {

			            $.each(returnData, function (key, value) {

			                var label = value.name;

			                if (value.location !== '' && value.location !== undefined) {
			                    label += ' locatie ' + value.location;
			                }

			                myArray.push({ label: label, value: value });
			            });

			            response(myArray);
			        });
			    },
			    search: function () {
			        // custom minLength
			        var term = this.value;
			        if (term.length < 3) {
			            return false;
			        }
			    },
			    focus: function () {
			        // prevent value inserted on focus
			        return false;
			    },
			    select: function (event, ui) {
			        var institution = ui.item.value;

			        setFields(institution);

			        return false;
			    }
			}).autocomplete('instance');
        instance._renderItem = _renderItem;
        instance._resizeMenu = _resizeMenu;
    }


    function searchForInstitution(event, email) {
        $('.domainListDiv').remove();

        // Get e-mail address
        email = email || $(this).val();

        // Does e-mail contain an @
        var atIndex = email.indexOf('@');

        if (atIndex > -1) {
            var domain = email.substr((atIndex + 1));

            if (domain !== '') {

                var clientSubscribeModel = {
                    data: domain
                }

                $.ajax({ url: '/api/institutions/search/email?data=' + domain, method: 'POST', data: clientSubscribeModel, dataType: 'json' })
                    .then(handleInstitutionData)
                ;

            }
        }

    }

    function handleInstitutionData(returnData) {

        var institutions = returnData;

        $('.domainListLabel,.domainList').remove();

        if (institutions.length > 0) {

            //var ul = $('<ul>');
            var div = $('<div class="clearfix formfield domainListDiv"><div class="editor-label"><label for="">&nbsp;</label></div></div>');

            var select = $('<select>');

            select.addClass('domainList');

            var editor = $('<div class="editor-field">').append(select);

            div.append(editor);

            div.insertAfter($('#EmailValidated').parent().parent());

            // Add default option
            var newOption = $('<option class=\'domainListItem\'>');

            newOption.html('<span>Selecteer je school</span>');
            newOption.data('school', 'header');

            select.append(newOption);

            // Add default header
          /*  var newOption2 = $('<option class="domainListItem" disabled="disabled" />');


            newOption2.data('school', 'header');

            select.append(newOption2);*/

            for (var j = 0; j < institutions.length; j++) {

                var newOptionSchool = $('<option class=\'domainListItem\'></option>').html(institutions[j].name);

                newOptionSchool.data('school', institutions[j]);

                select.append(newOptionSchool);
            }
            
            // Add default not found
            var newOptionNotInList = $('<option class=\'domainListItem\'></option>');

            newOptionNotInList.html('Mijn school staat er niet tussen');
            newOptionNotInList.data('school', 'notfound');

            select.append(newOptionNotInList);
            


            //var label = $("<div class='domainListLabel'>Enkele suggesties gebaseerd op je e-mail adres:</div>");
            //label.insertBefore($('.domainList'));

            // var width = select.parent().innerWidth();
            setTimeout(function () {
                $('.domainList').institutionselectmenu({
                    escapeHtml: false,
                    //width: 200 //width
                });


                var menuid = select.attr('id') + '-menu';
                var buttonid = select.attr('id') + '-button';

                $('#' + menuid).addClass('domainListMenu');
                $('#' + buttonid).addClass('domainListButton');

                //$('.domainListMenu').width(540);
                $('.domainListMenu').css({
                    'margin-left': '-100px',
                    'min-width': 540
                });
                //$('.domainListMenu').width(540);

                setTimeout(function () {
                    $('.domainListButton').focus();

                }, 10);

            }, 10);

        }
    }
    function _resizeMenu() {
        var defaultWidth = 320;

        var calc = $('body').width();

        calc /= 3;
        calc = Math.min(calc, 2 * defaultWidth);
        calc = Math.max(calc, defaultWidth);

        calc = Math.floor(calc);

        this.menu.element.outerWidth(calc);
    }
    function _renderItem(ul, item) {

        if (ul.children().length === 0) {
            var liHeader = $('<li>', { text: '' });
            liHeader.addClass('institution-table');

            liHeader.addClass('ui-selectmenu-optgroup  ui-state-disabled ui-menu-divider');
            //liHeader.addClass('ui-state-disabled ui-menu-divider');


            liHeader.html('<span class="institution-row"><span class="col1 selectmencol"><strong>Naam</strong></span><span class="col2 selectmencol"><strong>Locatie</strong></span><span class="col3 selectmencol"><strong>Plaats</strong></span></span>');

            liHeader.appendTo(ul);

           // liHeader.data("ui-selectmenu-item", item);
        }
        var li = $('<li>', { text: item.label });
        li.addClass('institution-table');
	    
        var schoolData = item.element ? item.element.data('school') : item.value;
        if (schoolData && typeof (schoolData.name) !== 'undefined') {

            var row = '<span class="institution-row"><span class="col1 selectmencol">' + schoolData.name;

            if (schoolData.location) {
                row += '<br/><small>' + schoolData.location + '</small>';
            }

            row += '</span><span class="col2 selectmencol">' + schoolData.address + ' ' + schoolData.housenumber + '</span><span class="col3 selectmencol">' + schoolData.city + '</span></span>';

            li.html(row);

            li.click(function (e) {

                setFields(schoolData);

                //$('#EmailValidated').focus();

                e.preventDefault();
            });

        }

        return li.appendTo(ul);
    }

})(jQuery, window.app);