(function ($, app) {
    'use strict';

    app.checkGoogleMaps = setupGoogleMaps;
    $(window).on('pageNavigation', app.checkGoogleMaps);
	
    function setupGoogleMaps() {

        var map = $('#map');
        if (map.length) {
            var adres = $('.adres', map);


            var location = adres.text().replace(/\s/g, ' ');
            var target = null;
            var title = adres.prop('title');

            if (title != null && title !== '') {
                var t = title.split(',');
                location = null;
                target = {
                    "lat": t[0],
                    "lng": t[1]
                };
            }

            if (!map.hasClass('mapobj')) {
                map.addClass('mapobj').GMap({
                    text: $('#map').html(),
                    addresspoint: target,
                    address: location,
                    zoomlevel: 13,
                    key: window.mapskey
                });
            }
        }
    };

    jQuery.GMap = {
        build: function (user_options) {
            var defaults = {
                text: '',
                lng: 0,
                lat: 0,
                zoomlevel: 8,
                key: '',
                target: null,
                address: '',
                addresspoint: null
            };
            return jQuery(this).each(
                function () {
                    var options = jQuery.extend(defaults, user_options);

                    var container = jQuery(this);
                    var map = null;
                    var target = null;
                    var marker = null;
                    var infowindow = null;
                    var geocoder = null;
                    var loading = false;
                    function activate() {
                        if (typeof google == 'undefined' || typeof google.maps == 'undefined') {
                            $(document).bind('mapsloaded', activate);
                            if (!loading) {
                                loading = true;

                                $.getScript('https://maps.google.com/maps/api/js?sensor=false&callback=mapsloaded'); //&key" + options.key
                            }
                        } else {
                            if (options.target == null && options.address != null) {
                                displayAddress(options.address);
                            } else if (options.target == null && options.addresspoint != null) {
                                displayPoint(options.addresspoint);
                            } else {
                                display();
                            }
                        }
                    };

                    function displayAddress(adres) {
                        if (typeof google.maps.Geocoder != undefined) {
                            geocoder = new google.maps.Geocoder();
                            geocoder.geocode({ 'address': adres }, function (results, status) {
                                if (status == google.maps.GeocoderStatus.OK) {
                                    options.target = results[0].geometry.location;
                                    display();
                                } else {
                                    alert('Geocode was not successful for the following reason: ' + status);
                                }
                            });
                        }
                    }

                    function displayPoint(point) {
                        options.target = new google.maps.LatLng(point.lat, point.lng)
                        display();
                    }

                    function display() {
                        var mapoptions = {
                            zoom: options.zoomlevel,
                            center: options.target,
                            mapTypeId: google.maps.MapTypeId.ROADMAP
                        }

                        map = new google.maps.Map(document.getElementById(container.prop('id')), mapoptions);

                        map.setCenter(options.target, options.zoomLevel);
                        marker = new google.maps.Marker({
                            position: options.target,
                            map: map
                        });

                        var infoBubble = new InfoBubble({
                            maxWidth: 200,
                            minWidth: 200,
                            minHeight: 80,
                            content: options.text,

                            hideCloseButton: true
                        });

                        infoBubble.open(map, marker);
                    }

                    activate();
                }); //end return this.each
        } //end build function
    }

    $.fn.GMap = jQuery.GMap.build;

})(jQuery, window.app);



function mapsloaded() {
    // this needs to be on global scope as it is called by Google Maps
    $(document).trigger('mapsloaded');
}