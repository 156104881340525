(function ($, app) {
	'use strict';



	$(window).on('pageNavigation', init);

	var showedPopUp = false;

	$("#pop-up-campaign").hide();
	var popUpCamaignDialog = $("#pop-up-campaign").dialog({
		autoOpen: false, modal: true, title: "Actie!", width: 600, open: function () {

		}
	});

	var congressForm = $("#subscribeAndContinueToShoppingCart").closest("form.subscribeFormElement");

	function init() {

		if (!app.subscribe()) {
			return;
		}
		
		$('.invoicemethod-selector :radio').on('change', onInvoiceMethodChange);
		onInvoiceMethodChange();

		$("#subscribeAndContinueToShoppingCart").on('click', subscribeAndContinueSubmit);

	}

	function subscribeAndContinueSubmit() {
		var isValid = congressForm.valid();

		if (!showedPopUp &&
			MedilexSubCount >= 3 && MedilexSubCount < 4 &&
			OneOnFiveDiscount &&
			$(".checked #InvoiceSubscriptionPreference")[0].value == "SingleInvoice" &&
			isValid) {
			showedPopUp = true;
			$("#pop-up-campaign").show();
			popUpCamaignDialog.dialog("open");
			return false;
		}
		else {
			return true;
        }
	}

	function onInvoiceMethodChange(ev) {
		var checked = $('.invoicemethod-selector :radio:checked');

		if (checked.length) {
            $('.login-no-continue').show();

			handleChange(checked.val());
		} else {
			handleChange('None');

		}
	}

	function handleChange(newValue) {
	
		var invoiceDetailsSingleOrPerPerson = $('.invoiceDetails-single-or-perperson');
		
		var subscribeAndReturn = $('#subscribeAndReturn');
		var subscribeAndContinueToShoppingCart = $('#subscribeAndContinueToShoppingCart');
		var hasSubscriptions = subscribeAndContinueToShoppingCart.data('hasSubscriptions') === 'true' || subscribeAndContinueToShoppingCart.data('hasSubscriptions');
		
	//	app.subscribeForm.updateEmailValidation();
		if (newValue && app.subscribeForm) {
			app.subscribeForm.updateEmailValidation();
		}
		switch(newValue) {
			case 'OnePerson':
				subscribeAndReturn.hide();
				subscribeAndContinueToShoppingCart.show();

				invoiceDetailsSingleOrPerPerson.show();
				discountCheck(false);

				break;
			case 'PerPerson':
				subscribeAndReturn.show();
				invoiceDetailsSingleOrPerPerson.show();

				if (hasSubscriptions) {
					subscribeAndContinueToShoppingCart.show();
				} else {
					subscribeAndContinueToShoppingCart.hide();
				}
				discountCheck(false);
				break;
			case 'SingleInvoice':
				subscribeAndReturn.show();
				invoiceDetailsSingleOrPerPerson.hide();
				if (hasSubscriptions) {
					subscribeAndContinueToShoppingCart.show();
				} else {
					subscribeAndContinueToShoppingCart.hide();
				}

				discountCheck(true);
				break;
		    case 'None':
		        $('.invoicemethod-selected').hide();
		        break;
		}
	}

	function discountCheck(groupDiscounts) {
		var discount = $('#DiscountId');

		var discountModel = JSON.parse(CanEveryXDiscount.replace(/&quot;/ig, '"'));
		if (discount.length) {
			var data = discount.data('discounts');
			discount.find('option').each(function (index, obj) {
				var el = $(obj);
				if (el.val() === '1') {
					//console.log('geen');
					return;
				}

				var discountData = data[el.val()];
				if (discountData.objectID === 2) {
					el.prop('disabled', !groupDiscounts);
					if (groupDiscounts) {
						el.text(discountData.name);
					} else {
						el.text('1 op 5 gratis alleen beschikbaar bij één factuur.');
						if (el.is(':selected')) {
							discount.val(1);
						}
					}
				}

				if (discountData.discountEveryXSubscription != null && discountData.discountEveryXSubscription > 0) {

					var canDiscount = false;
					discountModel.forEach(function (obj) {
						if (obj.DiscountId == discountData.objectID) {
							canDiscount = obj.CanDiscount;
							return false;
                        }
					});


					el.prop('disabled', !canDiscount);
					if (canDiscount) {
						el.text(discountData.name);
					} else {
						el.text('Alleen beschikbaar bij elke ' + discountData.discountEveryXSubscription + ' inschrijvingen');
						if (el.is(':selected')) {
							discount.val(1);
						}
					}
                }
			});
			if (discount.data('ui-selectmenu')) {
				discount.selectmenu('refresh');
			}

		}
	}



}(jQuery, window.app));