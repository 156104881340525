(function ($, app) {
	'use strict';

	$(window).on('pageNavigation', init);

	function init() {

		var reviewForm = $('#feedbackform');

		if (!reviewForm.length) {
			return;
		}
		reviewForm.submit(function (e) {
			// Open twitter scherm afhankelijk van vinkje
			if ($('#Twitter').is(':checked')) {

				var content = $('#Content').val();

				// Open twitter 
				window.open('https://twitter.com/intent/tweet?text=' + encodeURIComponent(content) + '&via=medilex', 'twitter', 'width=570,height=590');
			}
		});
	}
})(jQuery, window.app);
