(function ($, app) {
	'use strict';

	$(window).on('pageNavigation', init);

	var firstrun = true;

	function init() {
		firstrun = true;
		$('.congresgegevens input').change(function () {
			fixSelectedWorkshops(firstrun);
		});

		fixSelectedWorkshops(firstrun);

		firstrun = false;
	}

	function fixSelectedWorkshops(firstRun) {
		$('.congresgegevens input:radio').each(function (i, obj) {
			var checked = $(obj).is(':checked');
			congressPartConstraints($(obj), checked, firstRun);
			if (checked) {
				$('input:checkbox', $(obj).parent()).prop('disabled', false);
			} else {
				$('input:checkbox', $(obj).parent()).prop('disabled', true).prop('checked', false);
			}
		});


		$('input[name="WorkshopIds[]"]').each(function (i, obj) {
			var checked = $(obj).prop('checked');
			//log("each: " + checked);
			checkWorkshopConstraints($(obj), checked, firstrun);
			if (checked) {
				$('ul input:checkbox', $(obj).parent()).prop('disabled', false);
			} else {
				$('ul input:checkbox', $(obj).parent()).prop('disabled', true).prop('checked', false);
			}
		});
	}


	function checkWorkshopConstraints(workshop, checked, firstrun) {

		var cnt = $('ul input:checked', workshop.parent()).length;

		var workshopSettings = window.Workshops[workshop.val()];

		var constraint = $('span.constraint', workshop.parent());

		if (firstrun && constraint.hasClass('error')) {
			return;
		}
		var constrainttxt = constraint.removeClass('error');


		if (workshopSettings.round > 0) {
			var allWorkshops = workshop.closest('ul');
			var allChecked = $('input:checked[data-round="' + workshopSettings.round + '"]', allWorkshops);

			

			var congressPartConstraint = allWorkshops.prev('span.constraint');
		//	console.log(workshopSettings.round, allChecked.length,allWorkshops, congressPartConstraint);
			if (allChecked.length > 1) {
				congressPartConstraint.addClass('error');
			}
		}

		if (checked && ((cnt > workshopSettings.maximal && workshopSettings.maximal > 0) || cnt < workshopSettings.minimal) && !firstrun) {
			constrainttxt.addClass('error');
		}
	}

	function congressPartConstraints(congressPart, checked, firstrun) {
		var cnt = $('ul .workshopcheckbox:checked', congressPart.parent()).length;


		var settings = window.Parts[congressPart.val()];
		var constraint = $('span.constraint', congressPart.parent());

		if (firstrun && constraint.hasClass('error')) {
			return;
		}

		constraint.removeClass('error');
		if (checked && ((cnt > settings.maximal && settings.maximal > 0) || cnt < settings.minimal) && !firstrun) {
			constraint.addClass('error');
		}

	}


}(jQuery, window.app));