(function ($, app) {
	'use strict';
	
	var run = false;

	app.userMenu = function () {
		if (!run) {

			var dropdownElement = $('.user-dropdownmenu');

			$('.user-dropdownmenu').on('click', function () {
				dropdownElement.toggleClass('is-toggled');
			});

			run = true;
		}
	}

	$(window).on('pageNavigation', app.userMenu);
})(jQuery, window.app);