
(function ($, app) {
	"use strict";
	$(function() {
		var cookieKey = "cookieWarningAccepted";
		var cookieAccepted = ($.cookie(cookieKey) || false) === "true";
		var el = $("[data-cookie-warning]");
		
		if (!cookieAccepted) {
			el.removeClass("hidden");
		}

		el.find("button#cookieButton, #cookieButtonAlt").on("click", function(e) {
			e.preventDefault();
			$.cookie(cookieKey, true, { expires: 365, path: "/" });
			el.addClass("hidden");
		});
	});


	var cookieButtonAlt = $("[data-cookie-warning] #cookieButtonAlt");
	var cookieButton = $("[data-cookie-warning] #cookieButton");

	if ($(window).width() < 960) {
		cookieButtonAlt.text(cookieButtonAlt.data('label-smallscreen'));
		cookieButton.text(cookieButton.data('label-smallscreen'));
		applyScrollToPrivacy();
	}

	$(window).resize(function () {
		if ($(window).width() < 960) {
			cookieButtonAlt.text(cookieButtonAlt.data('label-smallscreen'));
			cookieButton.text(cookieButton.data('label-smallscreen'));

			applyScrollToPrivacy();
		}
	});

	function applyScrollToPrivacy() {
		if (location.pathname.replace(/^\//, '') === "privacy") {
			let target = $(".page-maincontent > h1:contains('Privacy')");
			if (target.length > 0) {
				var headerHeight = $("div.header").height();
				$("html, body").animate({
					scrollTop: target.offset().top - headerHeight
				}, 500, function () { });
			}
		}
	}

})(jQuery, window.app);