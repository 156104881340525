(function ($) {
    'use strict';


/*    $('.dropdown-toggle').on('click', function (el) {
        console.log($('.menubar-items').toggleClass('submenu-active'));
    });

    $(document).on('hide.bs.collapse', function (e) {
        $('.menubar-items').removeClass('submenu-active');
    });*/

    $(document).on('hide.bs.dropdown', function (e) {
        $('.menubar-items').removeClass('submenu-active');
    });

    $(document).on('show.bs.dropdown', function (e) {
        $('.menubar-items').addClass('submenu-active');
    });

    $(document).on('scroll', function (e) {
        var el = $('.menu-bar');
        var scroll = $(window).scrollTop();
        if (scroll > 0) {
            $('.menu-bar').addClass('scroll');
        } else {
            $('.menu-bar').removeClass('scroll');
        }

    });

})(jQuery);
