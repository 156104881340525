
(function($, app) {

    app.scaninfo = scaninfo;

    function scaninfo() {

        var regexp = /.*\[(.*)\].*/gi;
        var regexpPopup = /.*\[\{(.*)\}\].*/gi;
        $('.editor-label label').each(function(i, ob) {
            regexp.lastIndex = 0;
            regexpPopup.lastIndex = 0;
            var that = $(ob);

            var html = that.html();

            var testPopup = regexpPopup.exec(html);
            if (testPopup !== null) {

                var popupBlock = '[{' + testPopup[1] + '}]';

                var title = html.replace(popupBlock, '');
                that.html(html.replace('[{' + testPopup[1] + '}]',
                        ' <span class="icon icon-ask showpopuplink" style="display:inline-block" data-popup="#txt_' + i + '"></span><div class="popup" id="txt_' + i + '" title="' + title +'">' + testPopup[1] + '</div>'));

            } else {

                var test = regexp.exec(that.html(html));
            if (test != null) {
                    that.html(html.replace('[' + test[1] + ']',
                        ' <span class="icon icon-ask" style="display:inline-block" title="' +
                        test[1].replace(/\"/gi, '\\"') +
                        '"></span>'));
                }
            }
        });

        $(window).trigger('rescanpopup');

        $('.field-validation-error').each(function(i, ob) {
            regexp.lastIndex = 0;
            var that = $(ob);
            var test = regexp.exec(that.html());
            if (test != null) {
                that.html(test[0].replace('[' + test[1] + ']', ''));
            }
        });
    }
}(jQuery, window.app));
