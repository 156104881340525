(function ($, app, window) {
    'use strict';

    $(window).on('pageNavigation', init);

    var run = false;

    function init(ev, url) {

		if (!app.congress()) {
			return;
		}

		if (typeof (url) === 'string') {
			console.log(url);
            $('.congressmenu a').removeClass('selMenu');
            $(".congressmenu a[href='" + url + "']").addClass('selMenu');
        }

        $('.contentcolumns:not(.handleAjax)').on('click', 'a', handleAjaxLink).addClass('handleAjax');

        if (!run && window.history && window.history.pushState) {
            var congressUrl = $('.congresheader').data('congress-url');

            // var cregexp = /.*\/congressen\/([a-zA-Z0-9\-\_]*)(\/(.*))*/ig;

            var cregexp = new RegExp('.*\/' + congressUrl + '\/([a-zA-Z0-9\-\_]*)([\/?](.*))*', 'ig');

	        cregexp.lastIndex = 0;


	        var parsed = cregexp.exec(document.location + '');

            if (parsed) {
                var baseUrl = parsed[1];

                $.address.state('/' + congressUrl + '/' + baseUrl).init(function () {
                    // Initialize jQuery Address
                    //   $nav.address();
                });
            }

            $('.relatedbox a').on('click', handleAjaxLink);

            $.address.externalChange(function () {

            	var regexp = new RegExp('^/' + congressUrl + '\/([A-Za-z0-9\-_]+)(\/([A-Za-z0-9\-_]+)){0,1}(#[A-Za-z0-9\-_]+){0,1}', 'gi');

            	//console.log(document.location.pathname,'^/' + congressUrl + '\/([A-Za-z0-9\-_]+)(\/([A-Za-z0-9\-_]+)){0,1}(#[A-Za-z0-9\-_]+){0,1}');

                regexp.lastIndex = 0;

                var test = regexp.exec((document.location.pathname));

                getCongressPage('/' + congressUrl + '/' + test[1] + '/' + (test[3] || test[2] || ''));
            });
        }

        run = true;
    }

    function handleAjaxLink(e) {
        if (!app.form.mayChangePage(e)) {
            return;
        }
        var self = $(this);
        var targetUrl = self.attr('href') || '';
        var fullTargetUrl = self.prop('href');

        if (targetUrl === '#' || targetUrl === '#nogo') {
            //e.preventDefault();
            return;
        }

        // If url is to another domain follow
        var currentHost = window.location.host;
        var targetHost = getLocation(fullTargetUrl).host;

        if (currentHost !== targetHost) {
            return;
        }

        var hash = targetUrl.substring(targetUrl.indexOf('#'));
        var pageHash = true;

	    if (hash.lenght && hash[0] !== '/') {
		    try {
			    pageHash = !$('#' + hash).length;
		    } catch (e) {
			    console.log('hash invalid : ' + hash);
		    }

		    if (!pageHash) {
			    e.stopPropagation();
			    return;
		    }
	    }

	    if (targetUrl !== '#' && targetUrl.indexOf('#nogo') < 0 && !self.hasClass('ui-selectmenu')) {
            loadCongressPage(self, e);
        }
    }

    function loadCongressPage(self,event) {
        var congressUrl = $('.congresheader').data('congress-url');
        //var regexp = /.*\/congressen\/(.*)\/(.*)/i;
        //var regexp2 = /.*\/congressen\/(.*)\/(.*){0,1}/i;

        var regexp = new RegExp('.*\/' + congressUrl + '\/(.*)\/(.*)', 'i');
        var regexp2 = new RegExp('.*\/' + congressUrl + '\/(.*)\/(.*){0,1}', 'i');

        regexp.lastIndex = 0;
        var url = '' + self.prop('href');

        var test = regexp.exec(url);
        regexp.lastIndex = 0;
        regexp2.lastIndex = 0;

        var test2 = regexp2.exec(document.location + '');

        if (test && test2) {
            if (urlPart(test[1]) === urlPart(test2[1])) {
                $.address.value(urlPart(test[2]));

                getCongressPage('/' + congressUrl + '/' + test[1] + '/' + urlPart(test[2]));
                event.preventDefault();
                return;
            }
            event.stopPropagation();
            return;
        }
    }

    function urlPart(u) {
        if (u.indexOf('/') > 0) {
            u = u.split('/')[1];
        }
        return u.toLowerCase().replace('#/', '').replace('/#', '').replace('#', '').replace('/', '');
    }


    function getLocation(href) {
        var l = document.createElement('a');
        l.href = href;
        return l;
    }

    function getCongressPage(url) {

        //	document.location = url;

    	// check current page

	    url = url.replace('//', '/');

        var currentUrl = $('#congressPageContext').data('url');

        if (url === currentUrl) {
            return;
        }

        if (currentUrl != null && currentUrl !== '') {
            //  $(window).data(currentUrl, $("#congressPageContext").html());
        }

        url = url.replace('?xhr=true', '');

        var data = $(window).data(url);

        var cleanurl = url.replace('?path=hover', '');
		if (data) {
			$(window).trigger('pageUnload');

            $('#congressPageContext').html(data);
            $('#congressPageContext').data('url', cleanurl);
            $(window).trigger('checkmaps');
            $('a.download').prop('target', '_blank');

            //selectSubMenuItem();
            $(window).trigger('pageNavigation', cleanurl);
        } else {
            $.ajax({
                url: cleanurl + '?xhr=true',

				success: function (theData, status, xhr) {
					$(window).trigger('pageUnload');
                    $('.congrestextcontent').html(theData);
                    $('#congressPageContext').data('url', cleanurl);
                    $('a.download').prop('target', '_blank');
                   // initPageChange();
                    //selectSubMenuItem();
                    $(window).trigger('pageNavigation', cleanurl);

                    $(window).data(url, theData).trigger('checkmaps');
                }
            });
        }

        //$(window).trigger("pageNavigation");

        // Only scroll on congress detail pages
        initialScroll();
    }


    function initialScroll() {
        if (!app.legacyMode) {
            // Only scroll on congress detail pages
            var congressContext = $('.contentcolumns');
            var congressubpages = $('.congressubpages');

            if (congressContext.length && congressubpages.length) {
                $(window).scrollTop(congressContext.offset().top);
            }
        }
    }
}(jQuery, window.app,window));