(function ($, app) {
	'use strict';

	$(window).on('pageNavigation', init);

	function init() {


			if ($('#Form_Question').length) {
				$('select:not(.country-selector)').selectmenu();
				//$("#Form_CongressId").change(function () {
				$('#Form_CongressId').on('selectmenuclose', function () {

					var selectedValue = parseInt($(this).val(), 10);
	

					if (selectedValue > 0) {

						//	 alert(window.Congresses[$(this).val()]);
				
						var userId = window.Congresses[selectedValue];

						//$("#Form_UserId").selectmenu("option", "" + userId); //.attr("disabled", "true");
						$('#Form_UserId').val(userId);
						$('#Form_UserId').selectmenu('refresh');
						$('#Form_UserId').selectmenu('option', 'disabled', true);
					} else {
					

						$('#Form_UserId').selectmenu('option', 'disabled', false);
					}
				}).trigger('change');
			}



	}


})(jQuery, window.app);



