(function ($, app) {
	'use strict';
	var once = 1;
	app.equalWidths = function () {
		$('.equalwidths').each(function (i, obj) {
			var maxWidth = 0;
			$('input,p,button,label', obj).each(function (j, ob) {
				if ($(ob).outerWidth() > maxWidth) {
					maxWidth = $(ob).outerWidth();
					// console.log('maxWidth: ' + maxWidth);
				}
			}).css({ "min-width": maxWidth });
		});
		if (once-- > 0) {
			setTimeout(function() {
				app.equalWidths();
			},150);
		}
	}
})(jQuery, window.app);
