(function ($, app) {
	'use strict';

	$(window).on('pageNavigation', congressSubscribeFormInit);

    function congressSubscribeFormInit() {
        if (!app.subscribe()) {
            return;
        }


        var emailField = $('#Email');
        var emailValidatedField = $('#EmailValidated');
        var invoiceEmailField = $('#InvoiceEmail');
        var invoiceEmailValidateField = $('#InvoiceEmailValidate');
        var emailvalidationmessage = $('.emailvalidationmessage');
        var attentionNotRequired = $('#Address_AttentionNotRequired');
		var address_Attention = $('#Address_Attention');

		var hasAlergiesField = $('#HasAllergies');
		hasAlergiesField.change(function () {
			if (hasAlergiesField.iCheck('update')[0].checked) {
				$('#DietaryRequirementsNotRequired').iCheck('uncheck');
			}
		});

        app.subscribeForm = { updateEmailValidation: updateEmailValidation };



        var block = $('.inline-login-subscribe-container');
        var blockHeight = block.height();
        block.height(blockHeight);

        block.children().css({
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0
        });

        var loginNoContinue = $('.login-no-continue').on('click', function(e) {
            $('.subscribe-fields-step').css({ 'display': '' });
            e.preventDefault();
            e.stopImmediatePropagation();
            e.target.blur();

            $('#HasChosenNotToLogin').val('True');

            $('.invoicemethod-selector :radio').first().trigger('change');

            var checked = $('.invoicemethod-selector :radio:checked');

            if (checked.length) {
                $('.invoicemethod-selected').show();
                $(this).hide();

                app.updateCongressSubscribe(1);
            }

            setTimeout(function() {
                block.height(0);

                var container = $('.takeoptionHelperContainer');

                if (container.length) {
                    $('.takeoptionHelperContainer').hide();

                }


            }, 25);
            app.equalWidths();
            return false;
        });

        if (block.is(':hidden') || $('.HasChosenNotToLogin').val() === 'True') {
            loginNoContinue.trigger('click');
            app.equalWidths();

        }

        $('.optienemennonauthenticated').on('click', function() {
            $('.subscribe-fields-step').hide();
            $('#HasChosenNotToLogin').val('False');
            block.height(blockHeight);
            $('.takeoptionHelperContainer').show();
        });

        $('input:submit').click(function(e) {
            $('#NextActionJs').val($(this).val());
        });


		$(".subscribeFormElement").on('submit', function (e) {

			//subscribe here
            var formElement = $(this);
			cleanAccredations(false);

			//Validate first
			formElement.validate();
			//Add validator to checked accredationfields
			accredationFieldValidator();
			dietaryValidator();

			var isValid = formElement.valid();

            console.log('Is valid form?', isValid);

			if (isValid) {
                if (typeof (window.ga) === 'function' && window.ga.hasOwnProperty('loaded') && window.ga.loaded === true) {
                    e.preventDefault();

					var form = this;
                    try {
                        ga('send', 'event', 'InschrijfFormulier', 'Verzenden', {
                            'hitCallback': function () {
                                form.submit();
                            }
                        });

                    } catch (ex) {
                        form.submit();
                    }

                }
                return true;
            }

			var validator = formElement.validate();

            var myerrors = validator.errorList.map(function (el, ix) {
                console.log(arguments);

                return {
                    element: el.element.id,
                    message: el.message,
                    method: el.method
            };
            });
            console.log(myerrors);
            if (typeof (window.ga) === 'function' && window.ga.hasOwnProperty('loaded') && window.ga.loaded === true) {
                try {


                    ga('send', 'event', 'InschrijfFormulier', 'Niet verzenden, validatie fout', JSON.stringify(myerrors));
		        } catch (e) {
		            console.log(e);
		        }
		    }

		    console.log('Form was invalid', validator.errorList, validator);

			return false;

		});

		attentionNotRequired.on('click, change', function () {
			address_Attention.valid();
        });




		if ($('#DiscountId option').length) {
			var jDiscountId = $('#DiscountId');

			jDiscountId.selectmenu({
				change:
					function (event, ui) {
						jDiscountId.trigger('change');
						$(window).trigger(app.events.discountChanged);
					}
			}
			);
		}

		var roleCache = {};

		$('#Role').autocomplete({
			source: function (request, response) {
				var term = request.term;
				if (term in roleCache) {
					response(roleCache[term]);
					return;
				}

				$.ajax({
					type: "POST",
					url: '/api/basedataroles/search',
					data: JSON.stringify({ role: request.term }),
					dataType: 'json',
					contentType: 'application/json',
					success: function (data, status, xhr) {

						//{id: "Botaurus stellaris", label: "Great Bittern", value: "Great Bittern"}
						var mapped = $.map(data, function (r) {
							return {
								id: r.objectID,
								label: r.title,
								value: r.parentRoleTitle || r.title
							}
						});
						roleCache[term] = mapped;
						response(mapped);
					}
				});

				//		$.post('/api/basedataroles/search', , 'json');
			},
			minLength: 2,
			select: function (event, ui) {
				/*log(ui.item ?
			        "Selected: " + ui.item.value + " aka " + ui.item.id :
			        "Nothing selected, input was " + this.value);*/
			}
		});

/*		if ($('#Salutation_Dhr').length) {
			$('#Salutation_Dhr').parent().parent().buttonset();
		}*/
		//// init popup for email
  //      $('.popupemail').dialog({
  //          autoOpen: false,
  //          position: {
  //              my: "left top",
  //              at: "right top",
  //              of: $('.emailExplainPopupLink span')
  //          }
  //      });

		//$('.emailExplainPopupLink').click(function (e) {
		//	$('.popupemail').dialog('open');
		//	e.preventDefault();
		//	return false;
		//});

		$('#Email,#EmailValidated,#InvoiceEmail,#InvoiceEmailValidate').on('change keypress blur', updateEmailValidation); //   updateEmailValidation();

		$('.congresgegevens input').change(function () {
			$(window).trigger(app.events.calculateCosts);
		});

		$('#EqualInvoiceAddress').on('change', updateInvoiceAddressState).trigger('change');

		$('.bigatcongresscontainer').hide();

		if ($('.accreditaties :checkbox').length) {
			$('.accreditaties :checkbox').button({
				icons: {
					primary: 'ui-icon-check'
				}
			});
			// remove old asp.net mvc checkbox infrastructure
			$('.accreditaties input:hidden').filter('[value=false]').remove();

			$('.accreditaties').Accreditations({ input: '#BIGNumber, #BIGAtCongress' });
            //$('.accreditaties :checkbox').change(function () {
                //checkAccreditationSelected();
            //})

            //checkAccreditationSelected();
		} 

	    $('input[data-toggle]').click(function() {
			var toggleTarget = $($(this).attr('data-toggle'));
		    toggleTarget.toggle();

			if ($(this).val() === 'Klik hier voor accreditatie(s)') {
				$(this).val('Verberg accreditatie(s)');
			} else {
				$(this).val('Klik hier voor accreditatie(s)');
			}
		});

		function checkAccreditationSelected() {
            if ($('.accreditaties :checkbox:checked').length > 0) {
                $('.bigatcongresscontainer').show();
            }
            else {
                $('.bigatcongresscontainer').hide();
            }
        }



		app.equalWidths();

		function updateEmailValidation() {

			var isIncompany = false;
			if (typeof isIncompanyForm === 'undefined' || isIncompanyForm === null) {
				isIncompany = false;
			}
			else {
				isIncompany = isIncompanyForm;
            }

			if ($('.invoicemethod-selector :radio:checked').val() === 'SingleInvoice' || isIncompany) {

				emailvalidationmessage.hide();
				invoiceEmailField.val('');
				invoiceEmailValidateField.val('');
				return;
			}

			emailvalidationmessage.show();


			emailvalidationmessage.removeClass('green');
			emailvalidationmessage.addClass('red');

			var currentInvoiceMail = invoiceEmailField.val();
			var currentInvoiceMailRepeated = invoiceEmailValidateField.val();
			var currentMail = emailField.val();
			var currentMailRepeated = emailValidatedField.val();

			if (!isIncompany) {

				if (currentInvoiceMail !== '') {
					// alternatief invoice emailadres
					if (validateEmail(invoiceEmailField.val())) {
						if (currentInvoiceMail === currentInvoiceMailRepeated) {
							emailvalidationmessage.html('Je factuur verzenden wij digitaal naar <span>' + currentInvoiceMail + '</span>, <a href="#InvoiceEmail" class="scrollto">wijzigen</a>.');
							emailvalidationmessage.addClass('green');
							emailvalidationmessage.removeClass('red');
						}
						else {
							emailvalidationmessage.html('Je alternatieve factuur e-mailadres komt niet overeen, <a href="#InvoiceEmail" class="scrollto">wijzigen</a>.');
						}
					}
					else {
						emailvalidationmessage.html('Wij verzenden je factuur digitaal <a href="#InvoiceEmail" class="scrollto">vul daarvoor een correct e-mailadres in</a>.');
					}
				}
				else if (currentMail !== '') {
					if (validateEmail(currentMail)) {
						if (currentMail === currentMailRepeated) {
							emailvalidationmessage.html('Je factuur verzenden wij digitaal naar <span>' + currentMail + '</span>, <a href="#Email" class="scrollto">wijzigen</a>.');
							emailvalidationmessage.addClass('green');
							emailvalidationmessage.removeClass('red');
						}
						else {
							emailvalidationmessage.html('Je e-mailadres komt niet overeen, <a href="#Email" class="scrollto">wijzigen</a>.');
						}
					}
					else {
						emailvalidationmessage.html('Wij verzenden je factuur digitaal <a href="#Email" class="scrollto">vul daarvoor een correct e-mailadres in</a>.');
					}
				}
				else {
					emailvalidationmessage.html('Wij verzenden je factuur digitaal <a href="#Email" class="scrollto">vul daarvoor een correct e-mailadres in</a>.');
				}
			}
		}
		//A timer set to set value of input[id^="AccreditationIds_"]. there appears to be a bug that it is set to nothing.
		setTimeout(function ()
		{
			$('input[id^="AccreditationIds_"]').each(function ()
			{
				$(this).val($(this).data("accreditationid"));
			});
		}, 100);
	}

	function updateInvoiceAddressState() {

		var p = $('.invoiceDetails');

		if ($(this).is(':checked')) {
			p.hide();
		} else {
			p.show();
		}

	}

	function cleanAccredations(clearAll)
	{
		var accreditations;
		if (clearAll) {
			accreditations = $(".accreditation-codes").find('[id*="accreditation-code_"]');
		}
		else {
			accreditations = $(".accreditation-codes").find('[id*="accreditation-code_"]').not(":visible");
		}

		accreditations.each(function (i, notSelectedAccreditation)
		{
			$('#' + notSelectedAccreditation.id).find(":input").val("");
		});
	}

	function accredationFieldValidator()
	{
		$('input[id^="AccreditationIds_"]').each(function ()
		{
			var codeInput = $('#accreditation-code_' + $(this).data("accreditationid")).find(":input");

			if ($(this).is(":checked") && codeInput.length > 0)
			{
				codeInput.rules('add', {
					required: true,
					messages: {
						required: "Verplicht."
                    }
				});
			}
			else {
				codeInput.rules('remove', 'required');
            }
		});
	}

	function dietaryValidator() {

		var dietaryRequirementsField = $('#DietaryRequirements');
		var dietaryRequirementsNotRequiredField = $('#DietaryRequirementsNotRequired');
		var hasAlergiesField = $('#HasAllergies');

		if (hasAlergiesField.iCheck('update')[0].checked && (dietaryRequirementsNotRequiredField.iCheck('update')[0].checked || !dietaryRequirementsField[0].value)) {
			dietaryRequirementsField.rules('add', {
				required: true,
				messages: {
					required: "Verplicht."
				}
			});
		}
		else {
			dietaryRequirementsField.remove('remove', 'required');
		}
	}

	function validateEmail(email) {
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}




	setTimeout(function () {
		$('.invoice-preference-set').change(function () {
			var newValue = $(this);
			var current = newValue.closest('ul').data('current-invoice-preference');

			if (newValue.val() !== current && $('#invoice-warning').is(':visible')) {
				if (confirm('Je hebt al inschrijvingen in je winkelmandje staan. Als je de factuurinstelling verandert zullen deze inschrijvingen verwijderd worden. Weet je zeker dat je dit wilt?')) {
					$.post("/api/shoppingcart/empty", function (data) {
						if (data === 'ok') {
							$('#invoice-warning').hide();
						} else {
							$('input[name="InvoiceSubscriptionPreference"][value="' + current + '"]').prop('checked', true);
						}
					});

				} else {
					$('input[name="InvoiceSubscriptionPreference"][value="' + current + '"]').prop('checked', true);
				}
			}
		});
	}, 500);

})(jQuery, window.app);