(function ($, app) {
    'use strict';

    $(window).on('pageNavigation', init);

    function init() {
    	$('#Form_CongressId,#Form_UserId,#TargetMarketId,#SubTargetMarketId').filter(':visible').selectmenu();


    }

	
})(jQuery, window.app);



