(function ($, app) {
    'use strict';
    
    $(window).on('pageNavigation', init);


    var queueUpdate = app.debounce(update, 50);

    function init() {
    	if (!app.subscribe()) {
            return;
        }
        
        $("input[name='CongressPartId'],input[name='WorkshopIds[]'],input[name='WorkshopSessionIds[]']").on('change', function () {
            queueUpdate();
        });

    }




    function update() {
       
        var congressPartId = $("input[name='CongressPartId']:checked").val();
        var workshopsIds = [];
        var workshops = $("input[name='WorkshopIds[]']:checked");
        $.each(workshops, function (ix, workshop) {
            workshopsIds.push($(workshop).val());
        });
        var workshopSessionIds = [];
        var workshopSessions = $("input[name='WorkshopSessionIds[]']:checked");

        $.each(workshopSessions, function (ix,workshopSession) {
            workshopSessionIds.push($(workshopSession).val());
        });

        var qs = '?cp=' + congressPartId + '&w=' + workshopsIds.join(',') + '&s=' + workshopSessionIds.join(',');
        
        $('.login_cp_wr').each(function(ix, elem) {
            var el = $(elem);

            var newUrl = el.data('base') + encodeURIComponent(qs);

            el.attr('href', newUrl);
        });

    }
})(jQuery,window.app);