(function($, app) {
    'use strict';


    if (!app.legacyMode) {
        var last = $('#brancheselector ul li:last');
        if (last.css('background-position-y') === 'center') {
            last.addClass('lastchild');
        }
    }

    $('#brancheselector ul').click(function(e) {
        $(this).toggleClass('clicked');
        if ($(this).prop('href') === '') {
            e.preventDefault();
        }

        if (typeof(clearSelection) === 'function') {
            clearSelection();
        }
    });

}(jQuery, window.app));