(function ($, app) {
	'use strict';

    $(document).ready(function () {
        $(window).trigger('pageNavigation');
        app.equalWidths();

        app.checkGoogleMaps();
        //$('input:submit').filter(':not(.custom-btn)').button({ icons: { primary: 'ui-icon-check' } });

        app.scaninfo();

        app.menuHighlighter();
    });

    $(window).load(function() {
        loadTwitterWidget($('#twitter'));

    });

    function loadTwitterWidget(box) {
        if (box.length) {
            var twitterLink = $('a', box);
            if (twitterLink.length) {
                var username = twitterLink.attr('href').replace('http://twitter.com/', '');

                try {
                    var twitteroptions = jQuery.parseJSON( /*"{" +*/twitterLink.data('config').replace(/\'/g, '"'));

                    var defaultoptions = {
                        limit: 3,
                        label: 'Twitter',
                        title: 'My tweets',
                        icon: false,
                        username: false
                    };

                    var opts = jQuery.extend({}, defaultoptions, twitteroptions);
                    var twittervolg = $('<a/>').attr('target', '_blank').attr('href', twitterLink.attr('href')).html(twitterLink.html());
                    box.html('').twit(username, opts);
                    twittervolg.appendTo($('#twitter').parent());
                } catch (exce) {
                }
            }
        }
    }
})(jQuery,window.app);