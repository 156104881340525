(function ($) {

	$(window).on('nsUpdateRequiredIf', '', update);

	$(document).on('ready pageNavigation', update);

	var scannedObjects = null;

	var events = 'change';

	var regex = /\w+$/gm;

	function update() {
		if (scannedObjects !== null) {
			scannedObjects.off(events, updateRequiredInformation);
		}
		var ids = $('[data-val-requiredif-dependentproperty]').map(function (ix, el) {
			
			var dependentOnProp = $(el).data('val-requiredif-dependentproperty');
			return dependentOnProp;
		});
		
		ids = unique(ids);

		var nodes = $.map(ids, function (i) {
			return document.getElementById('Form_' + i);
		});

		$(nodes).on('change nsUpdateRequiredInformation', updateRequiredInformation).trigger('nsUpdateRequiredInformation');
		// updateRequiredInformation();
	}
	
	function updateRequiredInformation() {
		var self = $(this);
		var ownName = self.attr('name');
        regex.lastIndex = 0;
		var dependentNameValues = regex.exec(ownName);

		if (dependentNameValues !== null) {
			var dependentName = dependentNameValues[0];


			var dependentElements = $('[data-val-requiredif-dependentproperty="' + dependentName + '"]');

			var checked = self.is(':checked');
			
			updateRequiredIfInformation(dependentElements.filter('[data-val-requiredif-operator="EqualTo"]'), checked);
			updateRequiredIfInformation(dependentElements.filter('[data-val-requiredif-operator="NotEqualTo"]'), !checked);

		}
	}

	function updateRequiredIfInformation(set, required) {
		set.parent().find('.requiredIf').css('visibility', required ? 'visible':'hidden');
	}

	function unique(array) {
		return $.grep(array, function (el, index) {
			return index === $.inArray(el, array);
		});
	}
}(jQuery));