(function ($, app) {
    'use strict';    

    $(".stapbudget-checkbox-container :checkbox").change(function () {
        checkStapBudgetSelected();
    });

    checkStapBudgetSelected();

    function checkStapBudgetSelected() {
        if ($('.stapbudget-checkbox-container :checkbox:checked').length > 0) {
            $('.stapbudget-birthdate-container, .stapbudget-info').slideDown();
        }
        else {
            $('.stapbudget-birthdate-container, .stapbudget-info').slideUp();
        }
    }

}(jQuery, window.app));