(function($) {

    jQuery.Accreditations = {
        build: function(user_options) {
            var defaults = {
                BIGRequired: [],
                input: null,
                inputType: ':checkbox'
            };

            return jQuery(this).each(
                function() {
                    var options = jQuery.extend(defaults, user_options);

                    var container = jQuery(this);

                    function activate() {
                        if ($(options.inputType, container).length) {
                            $(options.inputType, container).bind('change', checkAccreditatie);
                            $(options.inputType, container).change();
                        } else {
                            $(options.input).parent().parent().hide();
                        }
                    };

                    function checkAccreditatie() {
                        var accreditationCodeDiv = $('#accreditation-code_' + $(this).data("accreditationid"));

                        var accreditationCodeName = accreditationCodeDiv.attr('data-accreditationcodename');

                        if (this.checked)
                        {
                            $(accreditationCodeDiv).show();
                        }
                        else
                        {
                            $(accreditationCodeDiv).hide();
                        }

                        if (testShowAccreditation($(':checked', container))) {
                            $('.accreditation-codes').slideDown();
                        } else {
                            $('.accreditation-codes').slideUp();
                        }
                    }

                    function testShowAccreditation(fields) {
                        window.AccreditationCode = window.AccreditationCode || [];
                        var show = false;
                        fields.each(function (i, obj) {
                            show = show || window.AccreditationCode[$(obj).data("accreditationid")] || false;
                        });
                        return show;
                    }
                    activate();
                }); //end return this.each
        } //end build function
    };

    jQuery.fn.Accreditations = jQuery.Accreditations.build;
}(jQuery));