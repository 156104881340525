(function ($, app) {
    /*global ga:false */
    'use strict';

    $('.send-friend').click(function (ev) {
        ev.preventDefault();
        getSendFriend();

    });

    
    $('.socialmediapopup')
        .attr('target','_blank')
        .on('click',
            function (ev) {
                if ($('.congressubpageslist').css('width') !== '139px') {
                    return;
                }
                var el = $(this);

                var width = 575,
        height = 400,
        left = ($(window).width() - width) / 2,
        top = ($(window).height() - height) / 2,
        url = this.href,
        opts = 'status=1' +
                 ',width=' + width +
                 ',height=' + height +
                 ',top=' + top +
                 ',left=' + left;

                
                window.open(el.attr('href'), 'twitter', opts);



                ev.preventDefault();

                if (typeof (window.ga) === 'function' && window.ga.hasOwnProperty('loaded') && window.ga.loaded === true) {
                    
                    var form = this;
                    try {
                        window.ga('send', 'event', 'SendAFriend', 'Delen',el.attr('href'));
                    } catch (e) {
                        form.submit();
                    }
                }
            });

    function getSendFriend() {
        $('.sendafriendform').remove();

        var congressUrl = $('.congresheader').data('congress-url');

        if ($(window).data('send-friend') == null) {

            //var regexp2 = /.*\/congressen\/(.*)\/{0,1}.*\#\/(.*)/gi;
            var regexp2 = new RegExp('.*\/' + congressUrl + '\/(.*)\/{0,1}.*\#\/(.*)', 'gi');

            regexp2.lastIndex = 0;
            var test = regexp2.exec(document.location);
            if (!test) {
                //regexp2 = /.*\/congressen\/(.*)\/.*/gi;
                regexp2 = new RegExp('.*\/' + congressUrl + '\/(.*)\/.*', 'gi');
                regexp2.lastIndex = 0;
                test = regexp2.exec(document.location);
            }
            $.ajax({
                url: '/' + congressUrl + '/' + test[1] + '/sendafriend',
                success: function (data) {
                    $(window).data('send-friend', data);
                    getSendFriend();
                }
            });

            return false;
        }
        var all = $($(window).data('send-friend'));
        for (var i = 0; i < all.length; i++) {

            if (all[i].tagName == 'SCRIPT') {

                $('body').append(all[i]); //If i don't append it to the body it wont evaluate the script

            } else {
                console.log(all[i]);

                $(all[i]).dialog({
                    autoOpen: false, modal: true, title: 'Send-a-friend', width: 600, open: function () {
                        $('#From').focus();
                    }
                }).dialog('open');

            }

        }


        prepareSendAFriendForm();
        return false;
    }

    function prepareSendAFriendForm() {
        var congressUrl = $('.congresheader').data('congress-url');

        $('.sendafriendform form').bind('submit', function (ev) {
            ev.preventDefault();
            //var regexp2 = /.*\/congressen\/(.*)\/{0,1}.*\#\/(.*)/gi;
            var regexp2 = new RegExp('.*\/' + congressUrl + '\/(.*)\/{0,1}.*\#\/(.*)', 'gi');

            regexp2.lastIndex = 0;
            var test = regexp2.exec(document.location);
            if (!test) {
                //regexp2 = /.*\/congressen\/(.*)\/.*/gi;
                regexp2 = new RegExp('.*\/' + congressUrl + '\/(.*)\/.*', 'gi');
                regexp2.lastIndex = 0;
                test = regexp2.exec(document.location);
            }
            $.ajax({
                //url: "/congressen/" + test[1] + "/sendafriend",
                url: '/' + congressUrl + '/' + test[1] + '/sendafriend',
                data: $('.sendafriendform form').serialize(),
                success: function (data) {
                    $('.sendafriendform').html($(data).find('form'));
                    $('#close-modal-sendafriend').bind('click', function (ev) {
                        ev.preventDefault();
                        $('.ui-dialog-titlebar-close').trigger('click');

                    }).button();
                    prepareSendAFriendForm();
                },
                error: function (xhr, error, e) {
                    $('.sendafriendform').html('<form><p>Bij het versturen is iets fout gegaan (foutcode: ' + error + '). Je kunt het opnieuw proberen of neem contact met ons op.</p><p><button type="reset" id="close-modal-sendafriend">Sluiten</button></p></form>');
                    $('#close-modal-sendafriend').bind('click', function (ev) {
                        ev.preventDefault();
                        $('.ui-dialog-titlebar-close').trigger('click');

                    }).button();
                },
                type: 'POST'
            });

            $('.sendafriendform').html('<p>Bezig met verzenden</p>');

        });

        $('.submitbtn').button();
        $('.cancelbtn').click(function () {
            $('.ui-dialog-titlebar-close').trigger('click');
        }).button();

    }


}(jQuery, window.app));