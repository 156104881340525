(function ($, app) {
    'use strict';


    $(document).ready(function () {
        if ($("[data-topnewsitem-display]").length > 0) {
            $("[data-close-newsitem]").click(function () {
                $("[data-topnewsitem-display]").hide();
                $.post("/api/topnewsitem/dismiss");
                $("[data-body]").removeClass("has-top-news-item");
                $(".user-menu-toggle").css("max-height", "calc(100vh - 90px)");
            });

            var height = $("[data-topnewsitem-display]").innerHeight();

            $(".user-menu-toggle").css("max-height", "calc(100vh - 90px - " + height + "px)");
        }
    });

})(jQuery, window.app);