(function ($,app) {

    app.utilities = app.utilities || {};


    app.utilities.displayEuro = function (mnt) {
        mnt -= 0;
        mnt = (Math.round(mnt * 100)) / 100;
        return ('&euro; ' + ((mnt === Math.floor(mnt)) ? mnt + '.00'
                  : ((mnt * 10 === Math.floor(mnt * 10)) ?
                           mnt + '0' : mnt))).replace('.', ',');
    }

}(jQuery,window.app));