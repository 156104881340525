window.app = window.app || {};

(function ($, app) {
    app.legacyMode = $('html').hasClass('lt-ie9');


    app.subscribe = function () {
        return (window.location.pathname).match(/inschrijven([\?\#].*){0,1}$/) || (window.location.pathname).match(/aankondiging\/.+\/aankondiging$/);
    }

    app.congress = function () {
        return $('.congresheader').length &&
            ($('.congressmenu a').length || $('.congresheader').data('congressUrl') === 'aankondiging') &&
            (window.location.pathname).match(/(congressen|cursussen|aankondiging)\/\S+$/);
    }

    app.shoppingcart = function () {
        return (window.location.pathname).match(/^\/winkelwagen/);
    }

})(jQuery, window.app);