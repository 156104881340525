
(function ($, app) {
    'use strict';

    $(document).ajaxSend(function () {
        $('#messages').remove();
        $('<div id="messages"/>').html('<div class="ui-state-highlight ui-corner-all loading-message">Bezig met laden</div>').appendTo('body').show();
    });

    $(document).ajaxStop(function () {
        $('#messages').hide();
    });

}(jQuery, window.app));