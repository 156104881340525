(function ($, app) {
	'use strict';

	$(window).on('pageNavigation', init);

	function init() {

		//if (!(window.location + "").match(/\/registreren/)) {
		//	return;
		//}


	    if (!$('.opdehoogte').length) {
	        return;
	    }

		//test

/*	    if ($('#Salutation_Dhr, #Form_Salutation_Dhr').length) {
	        $('#Salutation_Dhr, #Form_Salutation_Dhr').parent().buttonset();
	    }*/

		var otherText = $('#OtherText');
		if (otherText.length) {
			
			var subTargetMarketId = $('#SubTargetMarketId');
			subTargetMarketId.selectmenu();

			subTargetMarketId.parent().parent().hide();
			//$('#SubTargetMarketId').selectmenu("refresh");
			refreshMenu();

			

			//$("#TargetMarketId").trigger('change');

			otherText.parent().parent().hide();
			otherText.val('');


			if ($('#TargetMarketId option').length) {
				var targetMarketId = $('#TargetMarketId');
				targetMarketId.selectmenu();

				//$("#TargetMarketId").change(function(e) {
				targetMarketId.on('change selectmenuchange', refreshMenu).trigger('change');

			}

			subTargetMarketId.on('change selectmenuchange', function (e) {
				var id = parseInt($(this).val(), 10);

				$.each(window.OtherText, function (index, value) {
					if (value.SubTargetMarketId === id) {
						if (value.other) {
							otherText.parent().parent().show();
						} else {
							otherText.parent().parent().hide();
							otherText.val('');
						}
					}
				});
			});
		}

	}

	function refreshMenu(e) {
		var subMenuSelectId = null;

		var id = parseInt($('#TargetMarketId').val(),10);
		var submarketId = $('#SubTargetMarketId');
		submarketId.empty();

		$.each(window.OtherText, function (index, value) {
			if (value.TargetMarketId === id) {
				submarketId.append(new Option(value.name, value.SubTargetMarketId, false, false));
				if (subMenuSelectId == null) {
					subMenuSelectId = value.SubTargetMarketId;
				}
			}
		});

		submarketId.val(subMenuSelectId);
		submarketId.selectmenu('refresh');

		submarketId.parent().parent().show();

		submarketId.trigger('change');
	}




})(jQuery, window.app);