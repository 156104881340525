(function ($, app) {
    'use strict';


    $(window).on('pageNavigation', init);
    
    function init() {

        
        $('.download-iframe-target[data-targeturl]')
            .each(function (i, domEl) {
                var el = $(domEl);

                var url = el.data('targeturl');
                var status = el.data('status');

                if (!el.attr('src') && url) {
                    el.data('targeturl', null);
                    setTimeout(function () {
                        el.attr('src', url);
                        
                        var timer = setInterval(function () {
                            var iframe = domEl;
                            var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
            
                            if (!iframeDoc || !iframeDoc.readyState) {
                                $(status).html('Automatisch downloaden niet gelukt, <a href="' + url + '" target="_blank">download handmatig starten</a>');
                                clearInterval(timer);
                                return;
                            }

                            // Check if loading is complete
                            if (iframeDoc.readyState === 'complete' || iframeDoc.readyState === 'interactive') {
                                if (iframeDoc.URL && iframeDoc.URL == 'about:blank') {
                                    $(status).html('Je certificaat is gedownload.');
                                } else {
                                    $(status).html('Er is mogelijk iets fout gegaan bij het downloaden. Heb je geen pdf kunnen downloaden? Probeer het opnieuw of neem contact met ons op.  <a href="' + url + '" target="_blank">Opnieuw proberen</a>');
                                }
                                clearInterval(timer);
                                return;
                            }
                        }, 4000);

                    }, 10);
                }
            });
    }

}(jQuery, window.app));