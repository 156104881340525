(function($, app) {
    'use strict';
    $(window).on('pageNavigation', init);

    function init() {
        var baseEl = $('.js-mailingSettings');
        if (!baseEl.length) {
            return;
        }

        $.datepicker.setDefaults($.datepicker.regional['nl']);

        setupDatepicker();
        styleDropdowns(baseEl);

        setupUnsubscribe(baseEl);
        setupSubscribe(baseEl);
    }

    function setupDatepicker() {
        $('#DisableMailingUntilDatepicker')
            .datepicker({
                dateFormat: 'dd-mm-yy',
                minDate: new Date(),
                showWeek: true,
                altFormat: 'yy-mm-dd',
                altField: '#Item_DisableMailingUntil',
                showOn: 'both',
                label: 'x'

            })
            .next('button')
            .html('')
            .button({
                icons: {
                    primary: 'ui-icon-calendar'
                },
                text: false
            })
            .css({ 'height': '20px','margin-top':'-1px' });
    }

    function styleDropdowns(el) {
        $('select', el).selectmenu({
            change: function(event, ui) {
                var syncParent = $(ui.item.element).parent();
                if (syncParent && syncParent.data('sync')) {
                    updateLevels($(syncParent.data('sync')), parseInt(ui.item.value,10));
                } else if (syncParent.attr('id') === 'Item_MailingDisableMode') {
                    handleUnsubscribe(el,parseInt(ui.item.value, 10));
                }
                console.log(ui.item.element);
                $(ui.item.element).trigger('ns.update', event, ui);
            }
        });
        $('.ui-selectmenu-button').css('vertical-align', 'middle');

       
    }

    function updateLevels(el, sector) {
        if (sector && el && app.mailingSectorLevels) {
            el.find('option').remove().end();

            jQuery.each(app.mailingSectorLevels,
                function(ix, level) {
                    if (level.MailingSectorId === sector) {
                        el.append('<option value="' + level.ObjectID + '">' + level.Level + '</option>');
                    }
                });
            el.find('option').first().prop('selected', true);
            el.selectmenu('refresh');
        }
    }

    function handleUnsubscribe(baseEl,val) {
        if (val === 1) {
            $('.temporaryUnsubscribeDate').hide();
            $('.permanentUnsubscribeData').show();
        } else {
            $('.temporaryUnsubscribeDate').show();
            $('.permanentUnsubscribeData').hide();
        }
    }

    function setupSubscribe(baseEl) {
        var unsubscribeform = $('.subscribeform', baseEl);

        var mailingFrequencyEl = unsubscribeform.find('#frequency');

        var levelEl = unsubscribeform.find('#Item_CurrentMailingSectorLevelId');

        mailingFrequencyEl.on('change ns.update',
            function () {   
           
                switch (parseInt(mailingFrequencyEl.val(), 10)) {
                    case 1:
                        levelEl.parent().hide();
                        break;
                    default:
                        levelEl.parent().show();

                        break;
                }
            });

        mailingFrequencyEl.trigger('ns.update');
    }

    function setupUnsubscribe(baseEl) {
        var unsubscribeform = $('.unsubscribeform', baseEl);

        var btn = unsubscribeform.find('[type=submit]')
            .prop('disabled', 'disabled');
        var unsubscribeEl = unsubscribeform.find('#Item_MailingDisableMode');

        var untilEl = unsubscribeform.find('#Item_DisableMailingUntil');

        var reasonEl = unsubscribeform.find('#Item_UnsubscribeReason');

        unsubscribeform.on('change ns.update',
            function () {
                switch (parseInt(unsubscribeEl.val(), 10)) {
                    case 0:
                        btn.button('option', 'disabled', untilEl.val() === '');
                        break;
                    case 1:
                        btn.button('option', 'disabled', reasonEl.val() === '');

                        break;
                }
                console.log(arguments);
            });



    }
}(jQuery, window.app));