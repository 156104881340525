(function($, app) {
	"use strict";


	$(window).on("pageNavigation", shoppingcartInit);

	function shoppingcartInit() {
		if (!app.shoppingcart()) {
			return;
		}

		var unClickedTxt = "Ik heb een introducé code";
		var clickedTxt = "Ik heb geen introducé code";

		var insertCodeTxt = "Invoeren";
		var removeCodeTxt = "Aanpassen";

		var inactiveIntroduce = $(".inactiveIntroduce");

		$("#guestCodeButton").on("click",
			function(ev) {
				var self = $(this);
				if (inactiveIntroduce.is(":hidden")) {
					inactiveIntroduce.show();
					self.html(clickedTxt);
				} else {
					inactiveIntroduce.hide();
					$("#GuestCode").val("");
					resetGuestCodeState();
					self.html(unClickedTxt);
				}
				self.blur();
				ev.preventDefault();
			});

		$(document).ready(function() {
			if ($("#GuestCode").val() !== "") {
				$("#guestCodeButton").trigger("click");
				$("#validateButton").trigger("click");
			}
			var hasCustomContactPerson = $("#HasCustomContactPerson");
			hasCustomContactPerson.on("change",
				function() {
					$(".optional-custom-contact-person").toggle(hasCustomContactPerson.is(":checked"));
					$(".optional-fillInvoice").toggle(!hasCustomContactPerson.is(":checked"));
				});
			$(".optional-custom-contact-person").toggle(hasCustomContactPerson.is(":checked"));


			$("#fillInvoiceAddress").button();

		});

		function validateGuestCode() {
			if ($("#GuestCode").val().length === 0 || $("#GuestCodeProvider").val().length === 0) {
				$("#introError").remove();
				$(
						'<div class="text-danger field-validation-error" id="introError">"Introducé code" en "Intruducé van" velden moeten ingevuld zijn</div>')
					.insertAfter("#GuestCodeProvider");
			} else {
				var guestCode = $("#GuestCode").val();
				var self = $("#validateButton");

				if (self.val() === insertCodeTxt) {
					$.ajax({
						type: "POST",
						dataType: "json",
						url: "/api/shoppingcart/validateguestcode",
						data: { code: guestCode },
						success: function(result) {
							if (result.isValid) {

								$("tr.normal,.hideForGuest").hide();
								$(".introduceOnly").show();
								self.val(removeCodeTxt);

								$("#GuestCode").hide();
								$("#GuestCodeProvider").hide();
								$('div.editor-label:contains("Introducé van")').hide();
								$("#introError").remove();

								$("#GuestCode").parent().prev().text("Introducé code: " +
									$("#GuestCode").val() +
									" van " +
									$("#GuestCodeProvider").val());
							} else {
								resetGuestCodeState();
								$("#introError").remove();
								$(
										'<div class="text-danger field-validation-error" id="introError">Ongeldige introducé code</div>')
									.insertAfter("#GuestCodeProvider");
							}
						}
					});
				} else {
					$("#GuestCode").val("");
					resetGuestCodeState();
					self.val(insertCodeTxt);
					$("#GuestCode").show();
					$("#GuestCodeProvider").show();
					$('div.editor-label:contains("Introducé van")').show();
					$("#introError").remove();
					$("#GuestCode").parent().prev().text("Introducé code");
				}
			}
		}


		$("#validateButton").on("click",
			function() {
				validateGuestCode();
			});

		function resetGuestCodeState() {
			$("tr.normal,.hideForGuest").show();
			$(".introduceOnly").hide();
		}

		$("#fillInvoiceAddress").click(function() {
			$.getJSON("/api/shoppingcart/getadressinvoice/" + $("#PrimaryContact option:selected").val())
				.done(function(json) {
						if (json != null) {
							$.each(json,
								function(index, value) {
									if (index !== "attentionNotRequired") {
										$("#InvoiceAddress_" +
											index[0].toUpperCase() +
											index.substring(1, index.length)).val(value);
									}
								});

							$("#InvoiceAddress_Country").val(json.country).parent()
								.find(".custom-autocompleteDropdown-input").val(json.country);
						}
					}
				);
		});

		$("input[name=Paymethod]").click(function() {
			if (!inactiveIntroduce.is(":hidden")) {
				validateGuestCode();
			} else {
				$("form[name=ShoppingCartForm]").submit();
			}
			return false;
		});

	}
})(jQuery, window.app);