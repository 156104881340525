
(function($, app) {

	$('.emailvalidationmessage').on('click', 'a.scrollto', null, function (e) {
		var el = $(this);
		var target = $(el.attr('href'));

		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top - 10
			}, 500, function() {
				target.focus();
			});
		}

		e.preventDefault();
		e.stopPropagation();
		return false;

	});
    
}(jQuery, window.app));
