(function (jQuery) {
    jQuery.extend(jQuery.validator.messages, {
        required: 'Dit is een verplicht veld.',
        remote: 'Controleer dit veld.',
        email: 'Vul hier een geldig e-mailadres in.',
        url: 'Vul hier een geldige URL in.',
        date: 'Vul hier een geldige datum in.',
        dateISO: 'Vul hier een geldige datum in (ISO-formaat).',
        number: 'Vul hier een geldig getal in.',
        digits: 'Vul hier alleen getallen in.',
        creditcard: 'Vul hier een geldig creditcardnummer in.',
        equalTo: 'Vul hier dezelfde waarde in.',
        accept: 'Vul hier een waarde in met een geldige extensie.',
        maxlength: jQuery.validator.format('Vul hier maximaal {0} tekens in.'),
        minlength: jQuery.validator.format('Vul hier minimaal {0} tekens in.'),
        rangelength: jQuery.validator.format('Vul hier een waarde in van minimaal {0} en maximaal {1} tekens.'),
        range: jQuery.validator.format('Vul hier een waarde in van minimaal {0} en maximaal {1}.'),
        max: jQuery.validator.format('Vul hier een waarde in kleiner dan of gelijk aan {0}.'),
        min: jQuery.validator.format('Vul hier een waarde in groter dan of gelijk aan {0}.')
    });
})(jQuery);