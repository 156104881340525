(function ($, app) {
	'use strict';

	$(window).on('pageNavigation', init);
	function init() {

		if ($('[data-checkallforname]').length > 0) {
			$('[data-checkallforname]').each(function () {
				var allForName = $(this);
				var elementName = allForName.data('checkallforname');

				var element = $('input[name="' + elementName + '"]');

				updateOnCheckboxForAllForName(allForName);
				element.change(function () {
					updateOnCheckboxForAllForName(allForName);
				});
					
			});
		}

		$('[data-checkallforname]').change(function () {
			updateOnChecklAllForName($(this));
		});

		function updateOnCheckboxForAllForName(allForName) {

			var elementName = allForName.data('checkallforname');
			var element = $('input[name="' + elementName + '"]');

			var count = element.length;
			var countChecked = $('input[name="' + elementName + '"]:checked').length;

			allForName.prop('checked', count == countChecked);
			allForName.iCheck('update');
		}

		function updateOnChecklAllForName(checkForAllName) {

			var elementName = checkForAllName.data('checkallforname');
			var elements = $('input[name="' + elementName + '"]');

			if (checkForAllName.is(':checked')) {
				elements.prop('checked', true);
				elements.iCheck('update');
			} else {

				var count = $('input[name="' + elementName + '"]').length;
				var countChecked = $('input[name="' + elementName + '"]:checked').length;

				if (count == countChecked) {
					elements.prop('checked', false);
					elements.iCheck('update');
				}
			}
		}
	}
})(jQuery, window.app);