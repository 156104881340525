(function ($, app) {
    'use strict';

    app.events = app.events || {};

    app.events.calculateCosts = 'calculateCosts';

    $(window).on(app.events.calculateCosts, updateCosts);

    $(window).on('pageNavigation', initCostsCalculation);

    var subscribeCostsClass = '.display-subscribe-costs';

    var costs = {
        congressAndWorkshopCosts: 0,
        totalCosts: 0,
        hasDiscount: false,
        displayVat: true
    };

    function initCostsCalculation() {
        if ($(subscribeCostsClass).hasClass('noVatCalculation')) {
            costs.displayVat = false;
        }
        updateCosts();
    }

    function updateCosts() {
        calculateCosts();

        var text = app.utilities.displayEuro(costs.totalCosts);
        if (costs.displayVat) {
            text += ' excl. btw';
        }
        if (costs.hasDiscount) {
            text += ' incl. korting';
        } else {
            if ($('#DiscountId :visible').length) {
                text += ' excl. korting';
            }
        }
        $(subscribeCostsClass).html(text);
    }

    function calculateCosts() {
        var result = 0;
        $('.congresgegevens input').each(function (i, obj) {
            var input = $(obj);
            if (input.is(':checked')) {
                if (input.prop('name').indexOf('Congres') >= 0) {
                    result += window.Costs[input.val()];
                } else {
                    if (input.prop('name').indexOf('WorkshopIds') >= 0) {
                        result += window.Workshops[input.val()].costs;
                    } else {
                        result += window.WorkshopSessionsCosts[input.val()];
                    }
                }
            }
        });
        costs.congressAndWorkshopCosts = result;

        calculateDiscount(result);
    }

    function calculateDiscount(exclusive) {
        var discountid = app.subscribe.currentDiscount.discountId;
        costs.hasDiscount = false;

        if (discountid > 0) {
            var discount = app.subscribe.discounts[discountid];
            // Show discount field
            if (discount.hasDiscountCode) {
                $('#DiscountCode').parent().parent().show();
            } else {
                $('#DiscountCode').val('');
                $('#DiscountCode').parent().parent().hide();
            }
            if (discount.amount > 0) {
                exclusive -= discount.amount;
                costs.hasDiscount = true;
            }
            else if (discount.price > 0) {
                exclusive = discount.price;
                costs.hasDiscount = true;
            }
            else if (discount.discountRate > 0) {
                costs.hasDiscount = true;
                exclusive = (exclusive / 100) * (100 - discount.discountRate);
            }
        }

        costs.totalCosts = exclusive;
    }
}(jQuery, window.app));