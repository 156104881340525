(function ($) {
    'use strict';

    $(document).ready(function () {
		if ($("#share-button").length) {
			
			var shareDropdown = new needShareDropdown(document.getElementById('share-button'));
			shareDropdown.share.mailto = getSendFriend;
		}
	});

	function getSendFriend() {
		$(".sendafriendform").remove();

		const pathArray = window.location.pathname.split("/");
		const congressUrl = pathArray[0];

		if ($(window).data("send-friend") == null) {
			var regexp2 = new RegExp('.*\/' + congressUrl + '\/(.*)\/{0,1}.*\#\/(.*)', 'gi');
			regexp2.lastIndex = 0;
			var test = regexp2.exec(document.location);
			if (!test) {
				regexp2 = new RegExp('.*\/' + congressUrl + '\/(.*)\/.*', 'gi');
				regexp2.lastIndex = 0;
				test = regexp2.exec(document.location);
			}
			$.ajax({
				url: '/' + congressUrl + '/' + test[1] + '/sendafriend',
				success: function (data) {
					$(window).data("send-friend", data);
					getSendFriend();
				}
			});

			return false;
		}
		const all = $($(window).data("send-friend"));
		for (var i = 0; i < all.length; i++) {

			if (all[i].tagName == "SCRIPT") {

				$("body").append(all[i]); //If i don't append it to the body it wont evaluate the script

			} else {
				
				$(all[i]).dialog({
					autoOpen: false, modal: true, title: "Send-a-friend", width: 600, open: function () {
						$("#From").focus();
					}
				}).dialog("open");

			}
		}

		prepareSendAFriendForm();
		return false;
	}

	function prepareSendAFriendForm() {
		const pathArray = window.location.pathname.split("/");
		var congressUrl = pathArray[0];

		$(".sendafriendform form").bind("submit", function (ev) {
			ev.preventDefault();
			var regexp2 = new RegExp('.*\/' + congressUrl + '\/(.*)\/{0,1}.*\#\/(.*)', 'gi');

			regexp2.lastIndex = 0;
			var test = regexp2.exec(document.location);
			if (!test) {
				regexp2 = new RegExp('.*\/' + congressUrl + '\/(.*)\/.*', 'gi');
				regexp2.lastIndex = 0;
				test = regexp2.exec(document.location);
			}
			$.ajax({
				url: '/' + congressUrl + '/' + test[1] + '/sendafriend',
				data: $(".sendafriendform form").serialize(),
				success: function (data) {
					$(".sendafriendform").html($(data).find("form"));
					$("#close-modal-sendafriend").bind("click", function (ev) {
						ev.preventDefault();
						$(".ui-dialog-titlebar-close").trigger("click");

					}).button();
					prepareSendAFriendForm();
				},
				error: function (xhr, error, e) {
					$(".sendafriendform").html("<form><p>Bij het versturen is iets fout gegaan (foutcode: " + error + '). Je kunt het opnieuw proberen of neem contact met ons op.</p><p><button type="reset" id="close-modal-sendafriend">Sluiten</button></p></form>');
					$('#close-modal-sendafriend').bind("click", function (ev) {
						ev.preventDefault();
						$(".ui-dialog-titlebar-close").trigger("click");

					}).button();
				},
				type: "POST"
			});

			$(".sendafriendform").html("<p>Bezig met verzenden</p>");

		});

		$(".submitbtn").button();
		$(".cancelbtn").click(function () {
			$(".ui-dialog-titlebar-close").trigger("click");
		}).button();

	}

})(jQuery);