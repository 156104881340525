(function ($, app) {
	'use strict';

	$(window).on('pageNavigation', init);

	function init() {
		$('.eventProgram a').each(function () {
			var link = $(this);
			link.parent().addClass('speaker-link-container');

			var href = link.attr('href');

			if (href.indexOf('sprekers/') > 0) {

				var icon = $('<span class="icon icon-ask speaker-link"></span>');

				link.prepend(icon);

				var linkPosition = link.position();

				var iconPosition = icon.position();

				var differenceY = linkPosition.top - iconPosition.top;
				var differenceX = linkPosition.left - iconPosition.left;


				if (differenceY > 5) {
					icon.css('top', differenceY - 3 + 'px');
				}

				if (differenceX > 5) {
					//			icon.css('left', differenceX - 3 + "px");
				}
			}
		});
	}
}(jQuery, window.app));