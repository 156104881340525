(function ($, app) {
	'use strict';

	$(window).on('pageNavigation', init);
	$(window).on('rescanpopup', init);

	var first = true;

	function init() {
		if (first) {
			scan();
			first = false;
		}

		$('.popup:not(.ui-dialog-content)').each(function (i, el) {
			var element = $(el);
			var id = element.attr('id');
			element.dialog({
				autoOpen: false,
				position: {
					my: "left top",
					at: "right top",
					of: '.showpopuplink[data-popup="#' + id + '"]'
				}
			});
		});
	}

	function scan() {
		$('body').on('click',
			'.showpopuplink',
			function (e) {
				e.preventDefault();
				var popupTarget = $(this).data('popup');

				var popupTargetEl = $(popupTarget);

				popupTargetEl.dialog('open');

				$(window).one('pageUnload', function () {
					console.log(popupTargetEl);
					try {
						popupTargetEl.dialog('close');
					} catch (e) {
						console.log(e);
					}
					try {
						popupTargetEl.dialog('destroy');
					} catch (ex) {
						console.log(ex);
					}
				});

				return false;
			});
	}
}(jQuery, window.app));