(function ($) {
    'use strict';

    $(document).ready(function () {

        $('input:checkbox, input:radio').not('.evaluation input:checkbox, .evaluation input:radio').iCheck({
            checkboxClass: 'icheckbox_minimal-grey',
            radioClass: 'iradio_minimal-grey',
        });

        $('input:checkbox, input:radio').on('ifChanged', function(event) {
			$(event.target).trigger('change');

			if ($(event.target).attr("data-accreditationid")) {
				$(event.target).val($(event.target).data("accreditationid"));
			}
        });
    });

})(jQuery);