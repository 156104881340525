(function ($) {
    'use strict';

    $(document).ready(function () {
        if ($(".quotes-slider").length) {
            $('.quotes-slider').bxSlider({
                controls: false,
                auto: true,
            });
        }
    });

})(jQuery);