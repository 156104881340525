
(function ($, app) {

    app.menuHighlighter = menuHighlighter;

    function menuHighlighter() {

        var currentLocation = window.location.pathname;
        var currentFullLocation = window.location.href;
        var all = $('.congressubpages .congressubpageslist a, .subpages:not(.faq) .subpageslist a');

	//    console.log(all);

	//    console.log(currentLocation);
        var activeMenuItems = all.removeClass('selMenu').filter("[href='" + currentLocation + "'],[href='" + currentFullLocation + "']").addClass('selMenu');

        var fullCurrentLocation = window.location.href;

       

        if (!activeMenuItems.length) {
            var found = null;

            found = findActive(all, fullCurrentLocation);

            if (found) {
                var obj = $(found);
                obj.addClass('selMenu');
            }

        } else {
            $('.currentPage').html(activeMenuItems[0].innerText);
        }
	   

        var menuItems = $('.menu li').removeClass('active');

        var allMenuItems = menuItems.find('a');
        var activeMenu = findActive(allMenuItems, currentLocation);

        if (currentLocation === '/goededoelen') {
        	activeMenu = allMenuItems[1];
        }

		if (currentLocation === '/opdehoogte') {
            activeMenu = allMenuItems[4];
        }

        if (!activeMenu) {
        	activeMenu = findActive(allMenuItems, fullCurrentLocation);
        }



        if (activeMenu) {
            var objMenu = $(activeMenu);
            objMenu.parent().addClass('active');
        }
    }

    function findActive(all, fullCurrentLocation) {

    	var found;
	    

	    all.each(function (ix, el) {
        	

            if (el.href.length <= fullCurrentLocation.length) {

                var startsWith = fullCurrentLocation.substring(0, el.href.length);
	            

                if (startsWith === el.href && (!found || el.href.length > found.href.length)) {

                	found = el;
					
                }
            }
        });
        
        return found;
    }
}(jQuery, window.app));
