(function ($, app) {
    'use strict';

    app.form = app.form || {};
    

    $(window).on('pageNavigation', init);

    app.form.mayChangePage = mayChangePage;

    function init() {
        
    	if (app.subscribe()) {

            // enable leave message

            app.form.hasPendingChanges = false;

            onSubscribeFormLeaving();
            
        }
    }

    function mayChangePage(event) {
        $(window).trigger('ns:forcedFormStorage');
        if (app.form.hasPendingChanges) {
            if (!confirm(window.confirmationMessage)) {
                if (typeof(event) === 'object' && typeof(event.stopImmediatePropagation) === 'function') {
                    event.stopImmediatePropagation();
                }
                return false;
            }
        }
        window.formPendingChanges = false;

        return true;

    }

    function onSubscribeFormLeaving() {
        $(window).trigger('ns:forcedFormStorage');
        window.confirmationMessage = 'Inschrijving nog niet voltooid, weet je zeker dat je de pagina wilt verlaten?';

        app.form.hasPendingChanges = false;

        var subscribeForm = $('.subscribeform');
        $(window).off('beforeunload', handleBeforeUnload);

        subscribeForm.find('input[type=text]').each(function () {
            var elem = $(this);
            elem.data('oldVal', elem.val());
            elem.on('propertychange change click keyup input paste', handleChangeEvent.bind(elem));
        });

        subscribeForm.find('[type=submit]').on('click', function () {
            $(window).off('beforeunload', handleBeforeUnload);
        });

        function handleChangeEvent() {
            
            var elem = this;
            
            if (elem.data('oldVal') !== elem.val() && !app.form.hasPendingChanges) {
                elem.data('oldVal', elem.val());
                app.form.hasPendingChanges = true;
                $(window).on('beforeunload', handleBeforeUnload);
            }
        }

        function handleBeforeUnload(event) {
            $(window).trigger('ns:forcedFormStorage');
            (event || window.event).returnValue = window.confirmationMessage;     // Gecko and Trident
            return window.confirmationMessage;                                // Gecko and WebKit
        }

    }


}(jQuery, window.app));