(function ($, app) {
	'use strict';

	$(document).ready(initDiscountCodeWidget);
	$(window).on('pageNavigation', initDiscountCodeWidget);



	app.subscribe = app.subscribe || {}
	app.subscribe.discounts = {};
	app.events = app.events || {};
	app.events.discountChanged = 'discountChanged';

	app.subscribe.currentDiscount = { discountId: 0 };

	var discountSelect = null;
	var discountCodeInput = null;

	var discounts = app.subscribe.discounts;

	var debounced = null;

	function initDiscountCodeWidget() {
		discountSelect = $('#DiscountId');

		if (!discountSelect.length) {
			return;
		}


		app.subscribe.discounts = discountSelect.data('discounts');

		discounts = app.subscribe.discounts;

		discountCodeInput = $('#DiscountCode');


		// setup events

		discountCodeInput.blur(checkDiscountCode);
		discountCodeInput.change(checkDiscountCode);

		$(window).on(app.events.discountChanged, checkDiscountCode);

		// run
		selectEarliestBirdDiscount();

		checkDiscountCode();


	}

	function selectEarliestBirdDiscount() {

		var selectedDiscount = app.subscribe.discounts[discountSelect.val()];

		Object.keys(app.subscribe.discounts).forEach(function (key) {
			var discount = app.subscribe.discounts[key];

			if (discount.earlyBirdMonths > 0 && discount.earlyBirdMonths > selectedDiscount.earlyBirdMonths) {
				selectedDiscount = discount;
            }
		});

		discountSelect.val(selectedDiscount.objectID);
    }


	function checkDiscountCode() {
		$('.field-validation-error[data-valmsg-for=DiscountCode]').remove();
		$('.discountCodeDescription').remove();

		var discountid = parseInt(discountSelect.val(), 10);
		var discountCode = discountCodeInput.val();

		if (discountCode != null && discountCode.length > 0 && discountid > 0) {
			if (debounced != null) {
				window.clearTimeout(debounced);
				debounced = null;
			}

			debounced = setTimeout(function () {

				var result = discountCodeCheck();

				result.then(function (ok) {
					if (ok) {
						discountid = app.subscribe.currentDiscount.discountId;

						var discount = discounts[discountid];
						discountCodeInput.after('<span class="discountCodeDescription" style="display: block">' + discount.name + '</span>');

					} else {
						discountCodeInput.after('<span class="field-validation-error" data-valmsg-for="DiscountCode" data-valmsg-replace="true">Onjuiste kortingscode</span>');
					}
				});
			}, 100);

		}


		app.subscribe.currentDiscount.discountId = discountid;


		app.subscribe.currentDiscount.congressId = $('#CongressId').val();

		$(window).trigger(app.events.calculateCosts);


	}

	function discountCodeCheck() {
		var discountid = discountSelect.val();
		var discount = discounts[discountid];
		var retResult = jQuery.Deferred();

		if (discount.hasDiscountCode && discountCodeInput.val().length > 0) {
			app.subscribe.currentDiscount.discountCode = discountCodeInput.val().trim();
			app.subscribe.currentDiscount.discountId = discountSelect.val();

			$.ajax({
				type: 'POST',
				url: '/api/subscribediscounts/validate',
				dataType: 'json',
				contentType: 'application/json; charset=utf-8',
				data: JSON.stringify(app.subscribe.currentDiscount),
				success: function (result) {
					if (result != null && typeof result == 'object') {
						if (result.discountId != null) {
							if (discounts[result.discountId] == null) {
								discounts[result.discountId] = {
									name: result.name,
									amount: result.amount,
									price: result.price,
									discountRate: result.discountRate,
									hasDiscountCode: true
								};
							}
							app.subscribe.currentDiscount.discountId = result.discountId;
							retResult.resolve(true);
							return;
						}

					}
					//retResult = result;
					retResult.resolve(false);

				}
			});
		} else {
			retResult.resolve(false);
		}

		return retResult.promise();
	}

}(jQuery, window.app));