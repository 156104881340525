(function ($, app) {
    'use strict';

    var optieForm;

    var optieformlink;

    $(window).on('pageNavigation', init);

    function init() {

        if (!app.subscribe()) {
            return;
        }

        optieForm = $('.optieform');

        if (!optieForm.length) {
            return;
        }

        $('.invoicemethod-selector :radio').on('change', onInvoiceMethodChange);



        optieformlink = $('.optieformlink');

        onInvoiceMethodChange();


        if (optieformlink.length) {
            optieformlink.hide();

            $('.optienemen').on('click keyup', showOptionForm);


        }

    }

    function showOptionForm(event) {
        optieForm.show();
        optieformlink.hide();
		$('.invoicemethod-selector :radio:checked').prop('checked', false).first().trigger('change');
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
    }

    function onInvoiceMethodChange(ev) {
        var checked = $('.invoicemethod-selector :radio:checked');

        if (checked.length) {
            checkForSubscribeOption();
        }
    }

    function checkForSubscribeOption() {
        optieForm.hide();
        optieformlink.show();
    }


}(jQuery, window.app));