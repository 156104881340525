(function ($, app) {
	'use strict';

	$(window).on('pageNavigation', init);

	function init() {

		var url = window.location.pathname;

		if (!(url.match(/\/registreren/) !== null || url.match(/\/persoonsgegevens/) !== null || url.match(/\/activeren/) !== null)) {
			return;
		}

/*		if ($('#Salutation_Dhr').length) {
			$('#Salutation_Dhr').parent().parent().buttonset();
			console.log((window.location + '').match(/\/persoonsgegevens/) !== null);
		}*/
	}
})(jQuery, window.app);
