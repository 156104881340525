(function ($) {
    'use strict';

    $(document).ready(function () {
        if ($(".select-select2").length) {
            $('.select-select2').select2({ dropdownAutoWidth: true, containerCssClass: "custom-select2", dropdownCssClass: 'custom-select2-dropdown' });
        }

        if ($(".select-select2-congressearch").length) {
            $('.select-select2-congressearch').select2({
                dropdownAutoWidth: true,
                containerCssClass: "custom-select2 select2-congressearch",
                dropdownCssClass: 'custom-select2-dropdown',
                minimumInputLength: 3,
                multiple: true,
                language: {
                    inputTooShort: function () {
                        return '';
                    },
                    noResults: function () {
                        return 'Geen nascholing gevonden'
                    }
                },
                allowClear: true,
                matcher: matchCustomSubtitle,
            });

            var currentString = $('.select-select2-congressearch').attr("data-current");

            if (currentString && currentString.length) {
                $('.select2-congressearch  .select2-search input').val(currentString);
            };

            $('.select2-congressearch').find("input").attr("placeholder", "Zoeken naar een nascholing");

            $('.select-select2-congressearch').on('select2:closing', function () {
                var currentQuery = $('.select2-congressearch .select2-search input').prop('value');
                setTimeout(function () {
                    if (currentQuery && currentQuery.length) {
                        $('.select2-congressearch  .select2-search input').val(currentQuery);
                    };
                }, 0);
            });
        }

        function matchCustomSubtitle(params, data) {
            if ($.trim(params.term) === '') {
                return data;
            }

            if (typeof data.text === 'undefined') {
                return null;
            }

            if (data.text.toLowerCase().indexOf(params.term.toLowerCase()) > -1) {
                return data;
            }

            if ($(data.element).data('subtitle').toString().toLowerCase().indexOf(params.term.toLowerCase()) > -1) {
                return data;
            }

            return null;
        }

    });

})(jQuery);